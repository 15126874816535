.formzz{
    background-color: #222020;
    display: grid; 
    grid-template-columns: 3fr 3fr 3fr ;
    justify-content: space-around;
    align-items: center;
    width: auto;
    padding: 0px;
    margin: 6px;
    border-radius: 5px;
}

.formzz span{
    margin: 0;
    padding: 0;
    color: white;
    font-size: 1rem;
}
.formzz select{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: gray;
    color: white;
    font-size: 1rem;
    border: none;
    outline: none;
}
